import React from "react";
import { styled } from "../../stitches.config";
import { h3 } from "../commonStyles/Fonts";
import Link from "../../components/Link"

export interface Block1x3HeaderProps {
  header: string;
  headerLink?: string;
  style?: string;
}

const Block1x3Header: React.FC<Block1x3HeaderProps> = ({
  header,
  headerLink,
  style,
}) => {
  return (
    <HeaderWrapper>
      {headerLink ?
        <Link to={headerLink} className={h3()}>{header}</Link>
        :
        <h3 className={h3()}>{header}</h3>
      }
    </HeaderWrapper>
  );
};


const HeaderWrapper = styled("div", {
  paddingBottom: "0px !important",
  display: "block !important",
  "& h3, a": {
    display: "block",
    color: "$grayscale100",
    paddingLeft: "20px",
    position: "relative",
    margin: "0",
    "@bp4": {
      marginTop: "0 !important",
      overflowX: "hidden",
    },
    "&:after,&:before": {
      content: "",
      position: "absolute",
      height: "8px",
      width: "8px",
      borderRadius: "50%",
      backgroundColor: "$primary",
    },
    "&:after": {
      left: "0px",
      top: "6px",
    },
    "&:before": {
      left: "0px",
      top: "18px",
    },
  },
})


export default Block1x3Header;
